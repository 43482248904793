import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { CompanyDomainAutocompleteForFindSimilarDemo } from "@/components/workflow-inputs/companyWebsiteAutocomplete";
import { SquareArrowUpRightIcon } from "lucide-react";

import { isValidUrl } from "@/lib/utils";
import { Broadcast, Database, Target, Users } from "@phosphor-icons/react";
import * as Tabs from "@radix-ui/react-tabs";

import axios from "axios";
import posthog from "posthog-js";
import React, { useState, useRef, forwardRef } from "react";
import { Toaster, toast } from "sonner";

interface PricingTier {
  name: string;
  price?: number;
  description: string;
  features: string[];
  isPopular?: boolean;
  buttonVariant?: "outline" | "default";
}

const PricingCard = ({ tier, yearlyDiscount }: { tier: PricingTier; yearlyDiscount: boolean }) => {
  const monthlyPrice = tier.price;

  return (
    <div
      className={`flex flex-col p-6 bg-white rounded-xl border ${
        tier.isPopular ? "border-2 border-[#7F5EFF]" : "border-light-border"
      } hover:shadow-lg transition-all relative`}>
      {tier.isPopular && (
        <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
          <Badge className="bg-[#7F5EFF] text-white">Most Popular</Badge>
        </div>
      )}
      <div className="text-sm text-black mb-2">{tier.name}</div>
      <div className="text-3xl font-medium mb-4">
        {monthlyPrice != null ? `$${monthlyPrice}` : "Custom"}
        {monthlyPrice != null && (
          <span className="text-sm text-colors-gray-modern-700">/mo{yearlyDiscount ? ", billed annually" : ""}</span>
        )}
      </div>
      <p className="text-colors-gray-modern-700 mb-6">{tier.description}</p>
      <ul className="space-y-3 mb-8">
        {tier.features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2">
            <svg className="w-5 h-5 text-colors-brand-700" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      {monthlyPrice != null ? (
        <Button
          className={`mt-auto ${tier.buttonVariant === "outline" ? "" : "bg-[#7F5EFF] hover:bg-[#8F5EFF]"}`}
          variant={tier.buttonVariant || "default"}
          onClick={() => {
            window.location.href = "https://app.meticulate.ai/sign-up/";
          }}>
          Get Started
        </Button>
      ) : (
        <CompanyIntelligenceRequestButton>
          <Button
            className={`mt-auto ${tier.buttonVariant === "outline" ? "" : "bg-[#7F5EFF] hover:bg-[#8F5EFF]"}`}
            variant={tier.buttonVariant || "default"}>
            Contact Us
          </Button>
        </CompanyIntelligenceRequestButton>
      )}
    </div>
  );
};

const Chip = ({ children }: { children: string }) => {
  return (
    <span className="inline mb-2 mt-1 px-1 py-0 rounded-lg bg-purple-100 text-purple-700 border-0 border-purple-400">
      {children}
    </span>
  );
};

export const CompanyIntelligenceRequestButton = ({ children }: { children?: React.ReactNode }) => {
  const lambdaBaseUrl = "https://cc23vqfjhgkrpnk2fvx3pxxtkq0grlhf.lambda-url.us-east-1.on.aws/";
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild>
        {children || (
          <Button
            variant="outline"
            className="w-30 bg-transparent"
            onClick={() => {
              posthog.capture("user_clicked_on_company_intelligence_platform");
            }}>
            Book demo
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Enterprise Demo</DialogTitle>
          <DialogDescription>
            We'd love to show you Meticulate's enterprise offerings and work with you! We'll be in touch as soon as possible.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="email" className="text-right">
              Email
            </label>
            <Input
              type="email"
              id="email"
              placeholder="youremail@gmail.com"
              className="col-span-3"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="note" className="text-right">
              Note
            </label>
            <AutosizedTextarea
              id="note"
              placeholder="Any thing you want us to know"
              className="col-span-3"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="submit"
              onClick={() => {
                posthog.capture("user_requested_access_to_company_intelligence_platform");
                axios
                  .get(lambdaBaseUrl + "?email=" + encodeURIComponent(email) + "&note=" + encodeURIComponent(note))
                  .then(() => {
                    toast.info("Thanks for requesting access! We'll be in touch!");
                    setEmail("");
                    setNote("");
                  })
                  .catch(() => {
                    toast.error("Something went wrong. Please try again.");
                  });
              }}>
              Submit
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const HowItWorksSection = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full relative py-10">
      <div className="text-2xl text-black mb-10 font-mono uppercase text-center w-full">
        <span className="md:inline block">How it works -</span>
        <span className="md:inline-block md:ml-2 block">The Meticulate Engine</span>
      </div>
      {/* <img src="/tmp_apollo_visual.png" className="w-full" /> */}
      <Carousel className="w-[75%] max-w-[1000px]">
        <CarouselContent>
          <CarouselItem className="basis-full items-center justify-center">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col md:w-[400px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">1. Data gathering</div>
                <div className="text-base text-paragraph-text-color font-light">
                  Meticulate consumes an enormous amount of data per company, both unstructured and structured, from the public web, data
                  providers, and customer internal data.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/data_ocean.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem>
          {/* <CarouselItem className="basis-full">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col md:w-[400px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">2. LLM pipelines</div>
                <div className="text-base text-paragraph-text-color font-light">
                  Our LLM & data pipelines then process the data streams, solving for credibility and comprehensiveness.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/pipelines.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem> */}
          <CarouselItem className="basis-full">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col md:w-[400px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">2. Company Profiles</div>
                <div className="text-base text-paragraph-text-color font-light">
                  We generate the most comprehensive automated company profiles available anywhere, comparable to what an investment or
                  sales analyst would produce in a few hours.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/company_profile.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem>
          <CarouselItem className="basis-full">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col md:w-[400px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">3. Powerful Search & Filter</div>
                <div className="text-base text-paragraph-text-color font-light">
                  Our LLM pipelines enable extremely powerful search and filter, allowing you to request company searches the same way you
                  would ask a human.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/search_and_filter.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem>
          <CarouselItem className="basis-full">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col w-[300px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">4. Custom Research</div>
                <div className="text-base text-paragraph-text-color font-light">
                  Our pipelines can also be deployed towards your customized research & scoring needs.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/custom_research.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
};

export const ForGTMSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-center w-full border-y border-dashed bg-secondary-white border-light-border md:pt-10 md:pb-20 pt-4 relative p-2">
      <div className="text-2xl text-black mb-10 font-mono uppercase">For GTM Teams</div>
      <Tabs.Root
        defaultValue="leads"
        className="flex md:flex-row flex-col w-full justify-between md:items-start items-center gap-4 max-w-[1000px]">
        <Tabs.List className="flex flex-col md:w-1/2 w-full md:items-start items-center gap-2">
          <Tabs.Trigger
            value="leads"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-orange-200 data-[state=active]:outline data-[state=active]:bg-colors-orange-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-orange-50">
            <Target className="mr-4 flex-shrink-0 w-5 h-5" />
            <div className="flex flex-col">
              <span className="text-left">Build hyper targeted lead lists for any ICP</span>
            </div>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="contacts"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-orange-200 data-[state=active]:outline data-[state=active]:bg-colors-orange-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-orange-50">
            <Users className="mr-4 flex-shrink-0 w-5 h-5" />
            <span className="text-left">Find the right contacts regardless of title</span>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="crm"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-orange-200 data-[state=active]:outline data-[state=active]:bg-colors-orange-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-orange-50">
            <Database className="mr-4 flex-shrink-0 w-5 h-5" />
            <div className="flex flex-col">
              <Badge className="w-20 mb-1 text-colors-gray-modern-300" variant="outline">
                Enterprise
              </Badge>
              <span className="text-left">Continuously refresh your CRM for leads & track updates</span>
            </div>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="signals"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-orange-200 data-[state=active]:outline data-[state=active]:bg-colors-orange-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-orange-50">
            <Broadcast className="mr-4 flex-shrink-0 w-5 h-5" />
            <div className="flex flex-col ">
              <Badge className="w-20 mb-1 text-colors-gray-modern-300" variant="outline">
                Enterprise
              </Badge>
              <span className="text-left">Define intelligent buying signals and custom-score leads</span>
            </div>
          </Tabs.Trigger>
        </Tabs.List>
        <div className="md:w-1/2 w-full">
          <AspectRatio ratio={3 / 2}>
            <Tabs.Content value="leads" className="absolute inset-0 w-full h-full">
              <img src="/lead_lists.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="contacts" className="absolute inset-0">
              <img src="/contacts.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="crm" className="absolute inset-0">
              <img src="/crm_enrich.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="signals" className="absolute inset-0">
              <img src="/signals.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
          </AspectRatio>
        </div>
      </Tabs.Root>
    </div>
  );
});

export const ForInvestorsSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-center w-full bg-white border-light-border md:pt-10 md:pb-20 pt-4 p-2 relative">
      <div className="text-2xl text-black mb-10 font-mono uppercase">For Investors</div>
      <Tabs.Root
        defaultValue="source"
        className="flex md:flex-row-reverse flex-col w-full justify-between md:items-start items-center gap-4 max-w-[1000px]">
        <Tabs.List className="flex flex-col md:w-1/2 w-full md:items-end items-center gap-2">
          <Tabs.Trigger
            value="source"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-blue-200 data-[state=active]:outline data-[state=active]:bg-colors-blue-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-blue-50">
            <Target className="mr-4 flex-shrink-0 w-5 h-5" />
            <span className="text-left">Source companies and discover hidden gems</span>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="theses"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-blue-200 data-[state=active]:outline data-[state=active]:bg-colors-blue-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-blue-50">
            <Users className="mr-4 flex-shrink-0 w-5 h-5" />
            <span className="text-left">Run targeted searches to match any investment thesis</span>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="score"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-blue-200 data-[state=active]:outline data-[state=active]:bg-colors-blue-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-blue-50">
            <Database className="mr-4 flex-shrink-0 w-5 h-5" />
            <div className="flex flex-col">
              <Badge className="w-20 mb-1 text-colors-gray-modern-300" variant="outline">
                Enterprise
              </Badge>
              <span className="text-left">Score & prioritize companies according to your firm's criteria</span>
            </div>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="taxonomy"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-blue-200 data-[state=active]:outline data-[state=active]:bg-colors-blue-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-blue-50">
            <Broadcast className="mr-4 flex-shrink-0 w-5 h-5" />
            <span className="text-left">Map out markets and create taxonomies</span>
          </Tabs.Trigger>
        </Tabs.List>
        <div className="flex flex-col md:w-1/2 w-full">
          <AspectRatio ratio={3 / 2}>
            <Tabs.Content value="source" className="absolute inset-0">
              <img src="/source_companies.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="theses" className="absolute inset-0">
              <img src="/investment_theses.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="score" className="absolute inset-0">
              <img src="/rubric_scoring.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="taxonomy" className="absolute inset-0">
              <img src="/taxonomy.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
          </AspectRatio>
        </div>
      </Tabs.Root>
    </div>
  );
});

export const PricingSection = forwardRef<HTMLDivElement>((_, ref) => {
  const [billingPeriod, setBillingPeriod] = useState<"monthly" | "yearly">("monthly");

  // Calculate the discounted price here
  const getYearlyPrice = (monthlyPrice: number) => {
    return monthlyPrice * 0.8; // 20% discount
  };

  return (
    <div
      className="flex flex-col items-center justify-center w-full border-y border-dashed bg-secondary-white border-light-border md:pt-10 md:pb-20 pt-4 p-2 relative"
      ref={ref}>
      <div className="text-2xl text-black mb-4 font-mono uppercase text-center w-full">Pricing</div>
      <div className="text-4xl text-black mb-6 font-medium text-center max-w-[700px]">Pricing for your usage levels</div>
      {/* <div className="text-base text-colors-gray-modern-700 text-center max-w-[600px] mb-12">
        Our AI-powered tool takes your ideas and turns them into captivating, reader-friendly content that resonates with your audience.
      </div> */}

      {/* Billing Toggle */}
      <div className="flex items-center gap-4 mb-12">
        <button
          className={`px-4 py-2 rounded-lg transition-all ${
            billingPeriod === "monthly" ? "bg-[#7F5EFF] text-white" : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setBillingPeriod("monthly")}>
          Monthly
        </button>
        <button
          className={`px-4 py-2 rounded-lg transition-all ${
            billingPeriod === "yearly" ? "bg-[#7F5EFF] text-white" : "bg-gray-200 text-gray-700"
          }`}
          onClick={() => setBillingPeriod("yearly")}>
          Yearly (20% off)
        </button>
      </div>

      {/* Pricing Cards */}
      <div className="grid md:grid-cols-3 gap-8 w-full max-w-[1200px]">
        {/* Free Tier */}
        <PricingCard
          tier={{
            name: "Free",
            price: 0,
            description: "Perfect for trying out Meticulate",
            features: ["5 searches per month", "50 company credits/month", "50 contact credits/month"]
          }}
          yearlyDiscount={billingPeriod === "yearly"}
        />

        {/* Basic Plan - Most Popular */}
        <PricingCard
          tier={{
            name: "Basic",
            price: billingPeriod === "yearly" ? getYearlyPrice(75) : 75,
            description: "For individuals and small teams",
            features: ["Unlimited searches", "1000 company credits/month", "1000 contact credits/month"],
            isPopular: true
          }}
          yearlyDiscount={billingPeriod === "yearly"}
        />

        {/* Enterprise Plan */}
        <PricingCard
          tier={{
            name: "Enterprise",
            price: undefined,
            buttonVariant: "outline",
            description: "Ideal for growing businesses",
            features: ["Everything in Basic", "CRM integrations", "Team collaboration", "Priority support", "Account tracking"]
          }}
          yearlyDiscount={billingPeriod === "yearly"}
        />
      </div>

      {/* Logos Section
      <div className="mt-20 text-center">
        <div className="text-colors-gray-modern-300 mb-8">Trusted by Top-tier product companies</div>
        <div className="flex flex-wrap justify-center items-center gap-8"></div>
      </div> */}
    </div>
  );
});

export function LandingPage() {
  const [company, setCompany] = useState("");
  const gtmSectionRef = useRef<HTMLDivElement>(null);
  const investorsSectionRef = useRef<HTMLDivElement>(null);
  const pricingSectionRef = useRef<HTMLDivElement>(null);

  const scrollToGTMSection = () => {
    gtmSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToInvestorsSection = () => {
    investorsSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToPricingSection = () => {
    pricingSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="flex flex-col dark relative font-outfit">
        <div className="h-15 bg-[rgba(255,255,255,0.56)] px-5 py-2 flex flex-row items-center backdrop-blur-md fixed z-50 w-full border-b border-light-border">
          <img src="/MeticulateIconSquare.svg" className="h-4" />
          <div className="text-xl ml-1 text-colors-brand-700 font-medium">Meticulate</div>
          <div className="hidden md:flex flex-row gap-8 ml-10">
            <div className="text-sm text-gray-500 cursor-pointer hover:text-gray-700" onClick={scrollToGTMSection}>
              For GTM teams
            </div>
            <div className="text-sm text-gray-500 cursor-pointer hover:text-gray-700" onClick={scrollToInvestorsSection}>
              For investors
            </div>
            <div className="text-sm text-gray-500 cursor-pointer hover:text-gray-700" onClick={scrollToPricingSection}>
              Pricing
            </div>
          </div>
          <div className="flex-grow"></div>
          <div className="flex flex-row items-center gap-2">
            <Button
              variant="outline"
              className="bg-transparent"
              onClick={() => {
                window.location.href = "https://meticulate.mintlify.app";
              }}>
              API
            </Button>
            <CompanyIntelligenceRequestButton />
            <Button
              className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white shadow-[0_0_10px_rgba(255,255,255,0.5)] hover:shadow-[0_0_10px_rgba(255,255,255,0.6)]"
              onClick={() => {
                window.location.href = "https://app.meticulate.ai";
              }}>
              Login
            </Button>
          </div>
        </div>
        <div className=" w-full relative" style={{ background: "linear-gradient(#bcbcc4, #c9c9d0 18%, #fff 40%)" }}>
          <img src="/glow.webp" className="absolute top-0 left-0 w-full opacity-100 md:h-auto h-1/2" />
          <div className="md:pt-60 pt-40 p-4 flex flex-col w-full items-center md:justify-center relative z-10">
            <div className="md:text-5xl text-4xl text-black font-medium text-center max-w-[700px]">
              <span className="text">Find your ideal customers like never before</span>
            </div>
            <div className="md:text-xl text-lg mt-5 mb-5 font-light text-colors-gray-modern-800 text-center opacity-80 max-w-[600px]">
              The powerful new way to find your ideal customer profile, track custom buying signals, and build{" "}
              <span className="text-[#4F3EDF] font-medium italic underline decoration-yellow-500 decoration">high quality</span> pipeline
            </div>
            <div className="flex flex-row gap-2 mb-16">
              <Button
                variant="outline"
                className="bg-transparent"
                onClick={() => {
                  window.location.href = "https://meticulate.mintlify.app";
                }}>
                API
              </Button>
              <CompanyIntelligenceRequestButton />
              <Button
                className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white mb-4 shadow-[0_0_10px_rgba(255,255,255,0.5)] hover:shadow-[0_0_10px_rgba(255,255,255,0.6)]"
                onClick={() => {
                  window.location.href = "https://app.meticulate.ai/sign-up/";
                }}>
                Try for free!
              </Button>
            </div>
            <div className="flex flex-col flex-wrap items-center justify-center w-full gap-2">
              <div className="flex flex-col items-center gap-2">
                <div className="text-colors-gray-modern-400 font-normal">Or try our lookalike companies search...</div>
                <div
                  className="flex flex-row items-center gap-4 bg-white rounded-lg p-2 mb-5 relative z-50"
                  style={{ transformStyle: "preserve-3d" }}>
                  <CompanyDomainAutocompleteForFindSimilarDemo
                    value={company}
                    placeholder="Company domain"
                    onChange={(e) => setCompany(e)}
                    onSubmit={({ value }) => {
                      setCompany(value);
                    }}
                  />
                  <Button
                    type="submit"
                    onClick={() => {
                      if (company && isValidUrl(company)) {
                        window.location.href = `/find-similar?company_domain=${encodeURIComponent(company)}`;
                      } else {
                        toast.error("Please enter a valid company domain");
                      }
                    }}
                    className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white">
                    Search
                  </Button>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center mt-20 mb-20">
              <div className="text-xl flex mb-5 font-normal text-colors-gray-modern-400 text-center">Our investors and customers</div>
              <div className="flex flex-row gap-x-10 gap-y-8 flex-wrap items-center content-center justify-center max-w-[1200px] px-4">
                <a
                  href="https://www.ycombinator.com/launches/KUx-meticulate-effortless-business-research"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img className="md:h-7 h-4 opacity-80" src="/yc_logo_expanded_orange.png" alt="Y Combinator" />
                </a>
                <img src="/leonis_capital.webp" className="md:h-10 h-6 rounded opacity-70 invert" alt="Leonis Capital" />
                <img src="/link_logo.png" className="md:h-8 h-5 rounded opacity-70" alt="Link Ventures" />
                <img src="/Cervin_logo.svg" className="md:h-16 h-10 rounded opacity-70" alt="Cervin" />
                <img src="/infogrid_logo.png" className="md:h-8 h-5 rounded opacity-70" alt="Infogrid" />
                <img src="/advintro.png" className="md:h-8 h-5 rounded opacity-70" alt="Advintro" />
                <img src="/headline.png" className="md:h-8 h-5 rounded opacity-70" alt="Headline" />
                <img src="/atomic_vc.png" className="md:h-8 h-5 rounded opacity-70" alt="Atomic" />
                <img src="/canaan_partners.png" className="md:h-8 h-5 rounded opacity-70" alt="Canaan Partners" />
                <img src="/jet_partners.png" className="md:h-8 h-5 rounded opacity-70" alt="Jet Partners" />
              </div>
            </div>

            <div className="flex flex-col items-center pt-10 pb-20 bg-secondary-white w-full border-y border-dashed border-light-border">
              <div className="text-2xl text-black mb-10 font-mono uppercase text-center w-full px-4">Real Searches from our Customers</div>
              <div className="grid md:grid-cols-3 gap-6 w-full max-w-[1200px] px-4">
                <div 
                  onClick={() => window.open("https://app.meticulate.ai/company_search_old/247d9b0f-ce94-4bd5-be8a-5d465b8b3c39", "_blank")}
                  className="bg-white/80 backdrop-blur-sm p-6 rounded-xl border-2 border-[#7F5EFF] hover:outline hover:outline-2 hover:outline-[#7F5EFF] hover:shadow-lg transition-all flex items-center cursor-pointer relative group"
                >
                  <SquareArrowUpRightIcon className="absolute top-4 right-4 text-[#7F5EFF] group-hover:text-[#7F5EFF] group-hover:stroke-[2.75px]" size={25} />
                  <p className="text-lg text-colors-gray-modern-700 pr-4 leading-loose">
                    <Chip>Logistics software companies</Chip> that use <Chip>multiple cloud compute providers</Chip> and have <Chip>DevOps teams</Chip>.
                  </p>
                </div>

                <div 
                  onClick={() => window.open("https://app.meticulate.ai/company_search_old/8452187a-e7a0-49ab-a3ac-e7b42d533f00", "_blank")}
                  className="bg-white/80 backdrop-blur-sm p-6 rounded-xl border-2 border-[#7F5EFF] hover:outline hover:outline-2 hover:outline-[#7F5EFF] hover:shadow-lg transition-all flex items-center cursor-pointer relative group"
                >
                  <SquareArrowUpRightIcon className="absolute top-4 right-4 text-[#7F5EFF] group-hover:text-[#7F5EFF] group-hover:stroke-[2.75px]" size={25} />
                  <p className="text-lg text-colors-gray-modern-700 pr-4 leading-loose">
                    <Chip>Enhanced rock weathering</Chip> companies for <Chip>carbon sequestration</Chip> that are <Chip>well-commercialized</Chip>.
                  </p>
                </div>

                <div 
                  onClick={() => window.open("https://app.meticulate.ai/company_search_old/963f98d7-ffa3-460d-977e-1122d7797ffd", "_blank")}
                  className="bg-white/80 backdrop-blur-sm p-6 rounded-xl border-2 border-[#7F5EFF] hover:outline hover:outline-2 hover:outline-[#7F5EFF] hover:shadow-lg transition-all flex items-center cursor-pointer relative group"
                >
                  <SquareArrowUpRightIcon className="absolute top-4 right-4 text-[#7F5EFF] group-hover:text-[#7F5EFF] group-hover:stroke-[2.75px]" size={25} />
                  <p className="text-lg text-colors-gray-modern-700 pr-4 leading-loose">
                    <Chip>Commercial real estate developers</Chip> that own <Chip>1M+ sq. ft. of space</Chip> and have <Chip>published ESG reports</Chip>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowItWorksSection />
      <ForGTMSection ref={gtmSectionRef} />
      <ForInvestorsSection ref={investorsSectionRef} />
      <PricingSection ref={pricingSectionRef} />
      <footer className="bg-secondary-white py-8 border-t border-dashed border-light-border">
        <div className="container mx-auto flex flex-col items-center">
          <img src="/MeticulateIconSquare.svg" alt="Meticulate Logo" className="h-8 mb-4" />
          {/* <div className="text-sm text-gray-500">© {new Date().getFullYear()} Meticulate. All rights reserved.</div> */}
        </div>
      </footer>

      <Toaster position="top-center" richColors />
    </>
  );
}
